import { usePage } from '@inertiajs/vue3';
import { marked } from 'marked';

import type { MExpressionInstance } from './types/cdm';

export const valueOf = (expression?: MExpressionInstance) => {
    if (expression?.value?.valueString) {
        return expression.value.valueString;
    }
    if (expression?.value?.identity) {
        return expression.value.identity;
    }
    if (expression?.value?.elements) {
        return expression.value.elements.map((e) => e.identity).join(', ');
    }

    return '';
};

function fallbackCopyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
    } catch (err) {
        //
    }

    document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string, formattedText?: string): void {
    if (navigator.clipboard && navigator.clipboard.write) {
        navigator.clipboard
            .write([
                new ClipboardItem({
                    'text/plain': new Blob([text], { type: 'text/plain' }),
                    'text/html': new Blob([formattedText || text], { type: 'text/html' }),
                }),
            ])
            .then();
        return;
    }

    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then();
        return;
    }

    fallbackCopyTextToClipboard(text);
}

export function retrieveFromLocalstorage(key: string): any {
    if (window.localStorage) {
        const value = window.localStorage.getItem(key);
        return value ? JSON.parse(value) : value;
    }
}

export function saveToLocalstorage(key: string, value: any): void {
    if (window.localStorage) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
}

export function getRouteQueryParameter(key: string): string | null {
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    return params.get(key);
}

export function getCsrfToken(): string {
    return (document?.head?.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)?.content;
}

export function determineBorstMeta(event: MouseEvent) {
    if (!(event.target instanceof HTMLElement)) {
        return;
    }

    const pathLocation = event.target.dataset?.location;
    const side = event.target.dataset.side;
    if (!pathLocation) {
        console.error('Could not determine location name');
        return null;
    }

    let hour: number | null = null;
    if (['Peripheral', 'Central', 'RA'].includes(pathLocation)) {
        const offsetRect = event.target.getBoundingClientRect();
        const offsetX = event.clientX - (offsetRect.left + offsetRect.right) / 2;
        const offsetY = event.clientY - (offsetRect.top + offsetRect.bottom) / 2;

        let angle = (Math.atan2(offsetY, offsetX) * 180) / Math.PI;
        if (angle < 0) angle += 360;
        angle = angle + 90;

        hour = Math.round(angle / 30);
        if (hour === 0) hour = 12;
        if (hour > 12) hour = hour - 12;
    }

    let extra: string | null = null;
    if (['Peripheral', 'Central', 'RA'].includes(pathLocation)) {
        const offsetRect = event.target.getBoundingClientRect();
        let leftRight = event.clientX < (offsetRect.left + offsetRect.right) / 2 ? 'L' : 'M';
        const topBottom = event.clientY < (offsetRect.top + offsetRect.bottom) / 2 ? 'B' : 'O';

        if (side === 'L') {
            leftRight = leftRight === 'R' ? 'M' : 'L';
        }

        extra = `${leftRight}${topBottom}Q`;
    }

    return { name: pathLocation, side, hour, extra };
}

const languages = import.meta.glob<{ default: { [fieldName: string]: string } }>('../lang/*.json', { eager: true });

export function translate(key: string, replace: { [fieldName: string]: string } = {}): string {
    const locale = usePage<SharedPageProps>()?.props?.locale?.code;
    if (!locale) {
        return key;
    }

    const translations = languages[`../lang/${locale}.json`];
    if (!translations) {
        throw new Error(`Unknown language ${locale}.`);
    }

    let translation = translations.default[key] || key;
    Object.keys(replace).forEach(function (key) {
        translation = translation.replace(':' + key, replace[key]);
    });

    return translation;
}

export function stripMarkdown(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
}

export function filesize(size: number) {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export function renderMarkdown(src: string, options?: marked.MarkedOptions): string {
    const renderer = new marked.Renderer();
    renderer.link = function (href, title, text) {
        const link = marked.Renderer.prototype.link.apply(this, arguments);
        return link.replace('<a', "<a target='_blank'");
    };

    marked.setOptions({
        renderer: renderer,
    });

    return marked(src || '', options);
}

// From https://stackoverflow.com/a/64622052
export function mergeAndMaintainRelativeOrder(arrays: string[][]): string[] {
    interface NodeElement {
        value: string;
        predecessor: Set<NodeElement>;
        successor: Set<NodeElement>;
        collected: boolean;
    }
    const elements: { [key: string]: NodeElement } = {};
    // For every element in all rules create NodeElement that will
    // be used to keep track of immediate predecessors and successors
    arrays.flat().forEach(
        (value) =>
            (elements[value] = {
                value,
                predecessor: new Set<NodeElement>(),
                successor: new Set<NodeElement>(),
                // Used when we form final array of results to indicate
                // that this node has already be collected in final array
                collected: false,
            }),
    );

    arrays.forEach((list) => {
        for (let i = 0; i < list.length - 1; i += 1) {
            const node = elements[list[i]];
            const nextNode = elements[list[i + 1]];

            node.successor.add(nextNode);
            nextNode.predecessor.add(node);
        }
    });

    function addElementsInArray(head: NodeElement, array: string[]) {
        let areAllPredecessorsCollected = true;
        head.predecessor.forEach((element) => {
            if (!element.collected) {
                areAllPredecessorsCollected = false;
            }
        });
        if (!areAllPredecessorsCollected) {
            return;
        }
        array.push(head.value);
        head.collected = true;
        head.successor.forEach((element) => {
            if (!element.collected) {
                addElementsInArray(element, array);
            }
        });
    }

    const results: string[] = [];
    Object.values(elements)
        .filter((element) => element.predecessor.size === 0)
        .forEach((head) => {
            addElementsInArray(head, results);
        });
    return results;
}
